import React from "react";
import { BottomScrollListener } from "react-bottom-scroll-listener";
import { getLocation } from "api/admin/requests";
import getLocalDate from "utils/getLocalDate";
import { PuffLoader } from "react-spinners";
import Modal from "../../../../components/Modal/Modal";
import { sortById } from "utils/sorting";
import { sortByKey } from "utils/sorting";

const Location = ({ imei }) => {
  const [showModal, setShowModal] = React.useState(false);
  const [locationInfo, setLocationInfo] = React.useState([]);
  const [filteredLocatioInfo, setFilteredLocatioInfo] = React.useState([]);
  const [inputFilter, setInputFilter] = React.useState({ idInput: false });
  const [sorting, setSorting] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [skip, setSkip] = React.useState(0)

  const hover = (e, key) => {
    e.preventDefault();
    let deviceRow = document.getElementById(`deviceRow${key}`);
    deviceRow.style.backgroundColor =
      deviceRow.style.backgroundColor === "aliceblue" ? "white" : "aliceblue";
  };

  React.useEffect(() => {
    if (locationInfo.length < 1) {
      setLoading(true);
      getLocation({ imei, skip }, async (data) => {
        const newArr = await dataManager(data)
        setLoading(false);
        setSkip(skip + 10)
        setLocationInfo(newArr);
        setFilteredLocatioInfo(newArr);
      });
    }
  }, [imei, locationInfo.length, getLocation]);

  const sortToggal = (key) => {
    const sortDeviceInfo = locationInfo.sort((a, b) => {
      console.log(a[key]);
      if (new Date(a[key]) < new Date(b[key])) {
        return sorting ? 1 : -1;
      }
      if (new Date(a[key]) > new Date(b[key])) {
        return sorting ? -1 : 1;
      }
      return 0;
    });
    setFilteredLocatioInfo(sortDeviceInfo);
    setSorting(!sorting);
  };

  const toggalModal = () => {
    setShowModal(!showModal);
  };

  const getInfo = () => {
    getLocation({ imei, skip }, async (data) => {
      const newArr = await dataManager(data)
      setSkip(skip + 10)
      setLocationInfo((prevInfo) => [...prevInfo, ...newArr]);
      setFilteredLocatioInfo((prevInfo) => [...prevInfo, ...newArr]);
    });
  };

  const handleFilter = (value) => {
    const newDeviceInfo = locationInfo.filter(
      (liveInfo) =>
        liveInfo.uid.includes(value) ||
        liveInfo.recordDate.includes(value) ||
        liveInfo.triggerName.includes(value) ||
        (liveInfo.location && liveInfo.location.address.includes(value)) ||
        liveInfo.ipAddr.includes(value)
    );
    setFilteredLocatioInfo(newDeviceInfo);
  };

  const dataManager = async (data) => {
    try {
      const newDataArray = data?.map(obj => {
        return obj.locationsData.map(item => ({
          _id: item._id,
          lat: item.lat,
          long: item.long,
          address: item.address,
          locationTime: item.locationTime,
          uid: item.deviceDbId,
          date: obj.date,
          imei: obj.imei,
          __v: obj.__v
        }));
      });

      const flattenedDataArray = newDataArray.flat();
      return flattenedDataArray
    } catch (error) {
      console.log(error)
      return []
    }
  }

  const filterBody = (
    <>
      <div class="flex items-center">
        <label for="remember-me" class="ml-2 block text-sm text-gray-900">
          From
        </label>
        <input
          id="remember-me"
          name="remember-me"
          type="date"
          class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
        />
      </div>
      <div class="flex items-center">
        <label for="remember-me" class="ml-2 block text-sm text-gray-900">
          To
        </label>
        <input
          id="remember-me"
          name="remember-me"
          type="checkbox"
          class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
        />
      </div>
    </>
  );

  return (
    <>
      <Modal
        // setShowModal={setShowModal}
        toggalModal={toggalModal}
        showModal={showModal}
        body={filterBody}
        test="hiii"
      />
      <BottomScrollListener onBottom={getInfo}>
        {(scrollRef) => (
          <div
            className={
              "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white"
            }
            style={{ height: 535, overflowY: "scroll" }}
            ref={scrollRef}
          // onScroll={(e) => onPageRequest(e)}
          >
            <div className="rounded-t mb-0 px-4 py-3 border-0">
              <div className="flex flex-wrap items-center">
                <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                  <h3
                    className={"font-semibold text-lg text-blueGray-700"}
                    style={{ float: "left" }}
                  >
                    Live Info
                  </h3>

                  <div
                    className="relative flex w-full flex-wrap items-stretch"
                    style={{ float: "right", width: 300 }}
                  >
                    <span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
                      <i className="fas fa-search"></i>
                    </span>
                    <input
                      type="text"
                      placeholder="Search here..."
                      onChange={(ev) => handleFilter(ev.target.value)}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring w-full pl-10"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="block w-full overflow-x-auto"></div>

            {!loading && locationInfo ? (
              locationInfo.length < 1 ? (
                <h2 style={{ textAlign: "center" }}>Nothing Synced</h2>
              ) : (
                <table className="items-center w-full bg-transparent border-collapse">
                  <thead>
                    <tr>
                      <th
                        className={
                          "px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left g-blueGray-50 text-blueGray-500 border-blueGray-100"
                        }
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // setInputFilter({ idInput: true });
                          // setFilteredLocatioInfo(locationInfo);
                          const sortedData = sortById(locationInfo, sorting);
                          setFilteredLocatioInfo(sortedData);
                          setSorting(!sorting);
                        }}
                      >
                        ID <i class="fas fa-sort"></i>
                      </th>
                      <th
                        className={
                          "px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        }
                        onClick={() => sortToggal("recordDate")}
                        style={{ cursor: "pointer" }}
                      >
                        Date <i class="fas fa-sort"></i>
                      </th>
                      <th
                        className={
                          "px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        }
                      >
                        Location
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {
                      // (inputFilter.idInput
                      //   ? filteredLocatioInfo
                      //   : locationInfo
                      // )

                      filteredLocatioInfo.map((liveInfo, key) => (
                        <>
                          {liveInfo.address && (
                            <tr
                              id={`deviceRow${key.toString()}`}
                              key={key.toString()}
                              onMouseEnter={(e) => hover(e, key.toString())}
                              onMouseLeave={(e) => hover(e, key.toString())}
                            >
                              <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 text-left flex items-center">
                                <div className="flex flex-col justify-center">
                                  <span
                                    className={
                                      "w-full font-bold text-blueGray-600"
                                    }
                                  >
                                    {liveInfo.uid}
                                  </span>
                                </div>
                              </th>
                              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4">
                                <div className="flex flex-col justify-center">
                                  <span
                                    className={
                                      "w-full font-bold text-blueGray-600"
                                    }
                                  >
                                    {getLocalDate(liveInfo.locationTime)}
                                  </span>
                                </div>
                              </td>
                              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm p-4">
                                <div className="flex flex-col justify-center">
                                  <span
                                    className={
                                      "w-full font-bold text-blueGray-600"
                                    }
                                  >
                                    {liveInfo.address ? liveInfo.lat : ""},{" "}
                                    {liveInfo.address ? liveInfo.long : ""}
                                  </span>
                                  <span className={"w-full text-blueGray-600"}>
                                    {liveInfo.address ? liveInfo.address : ""}
                                  </span>
                                </div>
                              </td>
                            </tr>
                          )}
                        </>
                      ))
                    }
                  </tbody>
                </table>
              )
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "100px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <PuffLoader />
              </div>
            )}
          </div>
        )}
      </BottomScrollListener>
    </>
  );
};

export default Location;
