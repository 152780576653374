import React from "react";
import ReactTooltip from "react-tooltip";
import { BottomScrollListener } from "react-bottom-scroll-listener";
import { getCallLogs } from "api/admin/requests";
import getLocalDate from "utils/getLocalDate";
import { PuffLoader } from "react-spinners";
import { sortById, sortByDate } from "utils/sorting";

import { AutoSizer, Column, Table } from 'react-virtualized';

const CallLogs = ({ imei, setLoaded }) => {
  const [callLogs, setCallLogs] = React.useState([]);
  const [filteredData, setFilteredData] = React.useState([]);
  const [sorting, setSorting] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [skip, setSkip] = React.useState(0)

  const hover = (e, key) => {
    e.preventDefault();
    let callRow = document.getElementById(`callRow${key}`);
    callRow.style.backgroundColor =
      callRow.style.backgroundColor === "aliceblue" ? "white" : "aliceblue";
  };

  React.useEffect(() => {
    if (callLogs.length < 1) setLoading(true);
    getCallLogs({ imei, skip }, async (data) => {
      const newArr = await dataManager(data)
      setLoading(false);
      setSkip(skip + 10)
      setCallLogs(newArr);
      setFilteredData(newArr);
      setLoaded((p) => ({ ...p, t3: true }));
    });
  }, [imei, getCallLogs]);

  const getCallog = () => {
    getCallLogs({ imei, skip }, async (data) => {
      const newArr = await dataManager(data)
      setSkip(skip + 10)
      setCallLogs((prevCallLogs) => [...prevCallLogs, ...newArr]);
      setFilteredData((prevCallLogs) => [...prevCallLogs, ...newArr]);
    });
  };
  const handleFilter = (value) => {
    const newData = callLogs.filter(
      (log) =>
        log.uid.toLowerCase().includes(value.toLowerCase()) ||
        log.date.toLowerCase().includes(value.toLowerCase()) ||
        log.number.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(newData);
  };

  const dataManager = async (data) => {
    try {
      const newDataArray = data?.map(obj => {
        return obj.callsData.map(item => ({
          _id: item._id,
          number: item.phoneNumber,
          duration: item.callDuration || "",
          recordDate: item.callTime || item.t,
          uid: item.deviceDbId,
          type: item.callType,
          date: obj.date,
          imei: obj.imei,
          __v: obj.__v
        }));
      });

      const flattenedDataArray = newDataArray.flat();
      const uniqueContacts = new Map();

      flattenedDataArray.forEach(call => {
        const key = `${call.number}-${call.uid}`;
        if (!uniqueContacts.has(key)) {
          uniqueContacts.set(key, call);
        }
      });

      const uniqueContactsArray = Array.from(uniqueContacts.values());
      return uniqueContactsArray
    } catch (error) {
      console.log(error)
      return []
    }
  }

  const renderRow = ({ rowIndex, style }) => (
    <div
      id={`callRow${rowIndex.toString()}`}
      index={rowIndex.toString()}
      onMouseEnter={(e) => hover(e, rowIndex.toString())}
      onMouseLeave={(e) => hover(e, rowIndex.toString())}
      style={{ ...style, display: "flex", justifyContent: "space-between", flexWrap: "wrap", overflowX: "scroll" }}

    >
      <div className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 text-left flex items-center" style={{ flex: '1 1 9%' }}>
        <div className="flex flex-col justify-center">
          <span
            className={"w-full font-bold text-blueGray-600"}
          >
            {filteredData[rowIndex].uid}
          </span>
        </div>
      </div>
      <div className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4" style={{ flex: '1 1 17%' }}>
        <div className="flex flex-col justify-center">
          <span
            className={"w-full font-bold text-blueGray-600"}
          >
            {getLocalDate(filteredData[rowIndex].recordDate)}
          </span>
          <span className={"w-full text-blueGray-600"}>
            {filteredData[rowIndex].triggerName}
          </span>
        </div>
      </div>
      <div className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4" style={{ flex: '1 1 20%' }}>
        <div className="flex flex-col justify-center">
          <span
            className={"w-full font-bold text-blueGray-600"}
          >
            {filteredData[rowIndex].number}
          </span>
          <span className={"w-full text-blueGray-600"}>
            {secondsToHms(filteredData[rowIndex].duration)}
          </span>
        </div>
      </div>
      <div className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 item-center" style={{ flex: '1 1 20%' }}>
        <div className="flex flex-col justify-center">
          <span className={"w-full ml-3 text-blueGray-600"}>
            {(() => {
              switch (filteredData[rowIndex].type) {
                case "MISSED":
                  return (
                    <>
                      <i
                        data-tip
                        data-for={"r" + rowIndex.toString()}
                        className="text-red-500 fas fa-phone-slash text-base mr-1"
                      ></i>
                      <ReactTooltip
                        id={"r" + rowIndex.toString()}
                        aria-haspopup="true"
                        role="example"
                      >
                        {filteredData[rowIndex].type}
                      </ReactTooltip>
                    </>
                  );
                case "INCOMING":
                  return (
                    <>
                      <i
                        data-tip
                        data-for={"r" + rowIndex.toString()}
                        className="text-pink-400 fas fa-phone text-base mr-1"
                      ></i>
                      <ReactTooltip
                        id={"r" + rowIndex.toString()}
                        aria-haspopup="true"
                        role="example"
                      >
                        {filteredData[rowIndex].type}
                      </ReactTooltip>
                    </>
                  );
                case "OUTGOING":
                  return (
                    <>
                      <i
                        data-tip
                        data-for={"r" + rowIndex.toString()}
                        className="text-lightBlue-500 fas fa-phone-alt text-base mr-1"
                      ></i>
                      <ReactTooltip
                        id={"r" + rowIndex.toString()}
                        aria-haspopup="true"
                        role="example"
                      >
                        {filteredData[rowIndex].type}
                      </ReactTooltip>
                    </>
                  );
                default:
                  return (
                    <>
                      <i
                        data-tip
                        data-for={"r" + rowIndex.toString()}
                        className="text-blueGray-500 fas fa-phone-volume text-base mr-1"
                      ></i>
                      <ReactTooltip
                        id={"r" + rowIndex.toString()}
                        aria-haspopup="true"
                        role="example"
                      >
                        REJECTED
                      </ReactTooltip>
                    </>
                  );
              }
            })()}
          </span>
        </div>
      </div>
    </div>
  );


  return (
    <BottomScrollListener onBottom={getCallog}>
      {(scrollRef) => (
        <div
          className={
            "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white"
          }
          style={{ height: 535, overflowY: "scroll" }}
          ref={scrollRef}
        >
          <div className="rounded-t mb-0 px-4 py-3 border-0">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                <h3
                  className={"font-semibold text-lg text-blueGray-700"}
                  style={{ float: "left" }}
                >
                  CallLogs
                </h3>

                <div
                  className="relative flex w-full flex-wrap items-stretch"
                  style={{ float: "right", width: 300 }}
                >
                  <span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
                    <i className="fas fa-search"></i>
                  </span>
                  <input
                    type="text"
                    placeholder="Search here..."
                    onChange={(ev) => handleFilter(ev.target.value)}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring w-full pl-10"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="block w-full overflow-x-auto"></div>

          {!loading && callLogs ? (
            callLogs.length < 1 ? (
              <h2 style={{ textAlign: "center" }}>Nothing Synced</h2>
            ) : (
              <>
                <table className="items-center w-full bg-transparent border-collapse">
                  <thead>
                    <tr>
                      <th
                        className={
                          "px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left g-blueGray-50 text-blueGray-500 border-blueGray-100"
                        }
                        style={{ cursor: "pointer" }}
                      >
                        ID
                      </th>
                      <th
                        className={
                          "px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        }
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          const sortedData = sortByDate(
                            callLogs,
                            sorting,
                            "recordDate"
                          );
                          setFilteredData(sortedData);
                          setSorting(!sorting);
                        }}
                      >
                        Date <i class="fas fa-sort"></i>
                      </th>
                      <th
                        className={
                          "px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        }
                        onClick={() => {
                          const sortedData = sortById(
                            callLogs,
                            sorting,
                            "number"
                          );
                          setFilteredData(sortedData);
                          setSorting(!sorting);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        Number <i class="fas fa-sort"></i>
                      </th>
                      <th
                        className={
                          "px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        }
                      >
                        Type
                      </th>
                    </tr>
                  </thead>


                </table>

                <AutoSizer>
                  {({ height, width }) => (
                    <Table
                      width={width}
                      height={height}
                      headerHeight={50}
                      rowHeight={60}
                      rowCount={filteredData.length}
                      rowGetter={({ index }) => filteredData[index]}
                      onScroll={({ clientHeight, scrollHeight, scrollTop }) => {
                        if (clientHeight + scrollTop >= scrollHeight) {
                          getCallog();
                        }
                      }}
                    >
                      <Column
                        width="100%"
                        dataKey="uid"
                        cellRenderer={renderRow}
                        flexGrow={1}
                      />
                    </Table>
                  )}
                </AutoSizer>
              </>
            )
          ) : (
            <div
              style={{
                width: "100%",
                height: "100px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <PuffLoader />
            </div>
          )}
        </div>
      )}
    </BottomScrollListener>
  );
};

const secondsToHms = (d) => {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
  var mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes, ") : "";
  var sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
  return hDisplay + mDisplay + sDisplay;
};

export default CallLogs;
