import React from "react";
import { AutoSizer, Column, Table } from 'react-virtualized';

import ReactTooltip from "react-tooltip";
import { BottomScrollListener } from "react-bottom-scroll-listener";
import { getSmsLogs } from "api/admin/requests";
import getLocalDate from "utils/getLocalDate";
import { PuffLoader } from "react-spinners";
import { sortById, sortByDate } from "utils/sorting";

const SmsLogs = ({ imei }) => {
  const [smsLogs, setSmsLogs] = React.useState([]);
  const [filteredData, setFilteredData] = React.useState([]);
  const [sorting, setSorting] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [skip, setSkip] = React.useState(0)

  const hover = (e, key) => {
    e.preventDefault();
    let smsLogsRow = document.getElementById(`smsRow${key}`);
    smsLogsRow.style.backgroundColor =
      smsLogsRow.style.backgroundColor === "aliceblue" ? "white" : "aliceblue";
  };

  React.useEffect(() => {
    if (smsLogs.length < 1) {
      setLoading(true);
      getSmsLogs({ imei, skip }, async (data) => {
        const newArr = await dataManager(data)
        setSkip(skip + 10)
        setLoading(false);
        setSmsLogs(newArr);
        setFilteredData(newArr);
      });
    }
  }, [imei, getSmsLogs]);

  const getSmsLog = () => {
    getSmsLogs({ imei, skip }, async (data) => {
      const newArr = await dataManager(data)
      setSkip(skip + 10)
      setSmsLogs((prevSmsLogs) => [...prevSmsLogs, ...newArr]);
      setFilteredData((prevSmsLogs) => [...prevSmsLogs, ...newArr]);
    });
  };

  const handleFilter = (value) => {
    const newData = smsLogs.filter(
      (log) =>
        log.uid.toLowerCase().includes(value.toLowerCase()) ||
        log.recordDate.toLowerCase().includes(value.toLowerCase()) ||
        log.number.toLowerCase().includes(value.toLowerCase()) ||
        log.message.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(newData);
  };

  const dataManager = async (data) => {
    try {
      const newDataArray = data?.map(obj => {
        return obj.smsData.map(item => ({
          _id: item._id,
          message: item.message,
          number: item.phoneNumber,
          recordDate: item.smsTime || "",
          type: item.smsType,
          uid: item.deviceDbId,
          date: obj.date,
          imei: obj.imei,
          __v: obj.__v
        }));
      });

      const flattenedDataArray = newDataArray.flat();
      const uniqueContacts = new Map();

      flattenedDataArray.forEach(sms => {
        const key = `${sms.phoneNumber}-${sms.message}-${sms.uid}`;
        if (!uniqueContacts.has(key)) {
          uniqueContacts.set(key, sms);
        }
      });

      const uniqueContactsArray = Array.from(uniqueContacts.values());
      return uniqueContactsArray
    } catch (error) {
      console.log(error)
      return []
    }
  }

  const renderRow = ({ rowIndex, style }) => (
    <div
      id={`smsRow${rowIndex.toString() + rowIndex.toString()}`}
      key={rowIndex.toString() + rowIndex.toString()}
      onMouseEnter={(e) =>
        hover(e, rowIndex.toString() + rowIndex.toString())
      }
      onMouseLeave={(e) =>
        hover(e, rowIndex.toString() + rowIndex.toString())
      }
      style={{ ...style, display: "flex", justifyContent: "space-between", flexWrap: "wrap", overflowX: "scroll" }}

    >
      <div className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4" style={{ flex: '1 1 15%' }}>
        <div className="flex flex-col justify-center">
          <span
            className={"w-full font-bold text-blueGray-600"}
          >
            {filteredData[rowIndex].uid}
          </span>
        </div>
      </div>
      <div className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm p-4" style={{ flex: '1 1 45%' }}>
        <div className="flex flex-col justify-center">
          <span
            className={"w-full font-bold text-blueGray-600"}
          >
            {filteredData[rowIndex].number}
          </span>
          <span className={"w-full text-blueGray-600"}>
            {filteredData[rowIndex].message.slice(0, 100) + "..."}
          </span>
        </div>
      </div>
      <div className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4" style={{ flex: '1 1 20%' }}>
        <div className="flex flex-col justify-center">
          <span
            className={"w-full font-bold text-blueGray-600"}
          >
            {getLocalDate(filteredData[rowIndex].recordDate)}
          </span>
          <span className={"w-full text-blueGray-600"}>
            {filteredData[rowIndex].triggerName}
          </span>
        </div>
      </div>
      <div className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4" style={{ flex: '1 1 20%' }}>
        <span className={"w-full ml-3"}>
          {filteredData[rowIndex].type === "sent" ? (
            <>
              <i
                data-tip
                data-for={"r" + rowIndex.toString() + rowIndex.toString()}
                className="text-lightBlue-500 fas fa-arrow-alt-circle-up text-base mr-1"
              ></i>
              <ReactTooltip
                id={"r" + rowIndex.toString() + rowIndex.toString()}
                aria-haspopup="true"
                role="example"
              >
                Sent
              </ReactTooltip>
            </>
          ) : (
            <>
              <i
                data-tip
                data-for={"r" + rowIndex.toString() + rowIndex.toString()}
                className="text-pink-400 fas fa-arrow-alt-circle-down text-base mr-1"
              ></i>
              <ReactTooltip
                id={"r" + rowIndex.toString() + rowIndex.toString()}
                aria-haspopup="true"
                role="example"
              >
                Recieved
              </ReactTooltip>
            </>
          )}
        </span>
      </div>
    </div>
  );

  return (
    <BottomScrollListener onBottom={getSmsLog}>
      {(scrollRef) => (
        <div
          className={
            "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white"
          }
          style={{ height: 535, overflowY: "scroll" }}
          ref={scrollRef}
        >
          <div className="rounded-t mb-0 px-4 py-3 border-0">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                <h3
                  className={"font-semibold text-lg text-blueGray-700"}
                  style={{ float: "left" }}
                >
                  Sms Logs
                </h3>

                <div
                  className="relative flex w-full flex-wrap items-stretch"
                  style={{ float: "right", width: 300 }}
                >
                  <span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
                    <i className="fas fa-search"></i>
                  </span>
                  <input
                    type="text"
                    placeholder="Search here..."
                    onChange={(ev) => handleFilter(ev.target.value)}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring w-full pl-10"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="block w-full overflow-x-auto"></div>

          {!loading && smsLogs ? (
            smsLogs.length < 1 ? (
              <h2 style={{ textAlign: "center" }}>Nothing Synced</h2>
            ) : (
              <>
                <table className="items-center w-full bg-transparent border-collapse table-fixed">
                  <thead>
                    <tr>
                      <th
                        className={
                          "px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left g-blueGray-50 text-blueGray-500 border-blueGray-100"
                        }
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          const sortedData = sortById(smsLogs, sorting);
                          setFilteredData(sortedData);
                          setSorting(!sorting);
                        }}
                      >
                        ID <i class="fas fa-sort"></i>
                      </th>
                      <th
                        className={
                          "px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        }
                      >
                        Message
                      </th>
                      <th
                        className={
                          "px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        }
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          const sortedData = sortByDate(
                            smsLogs,
                            sorting,
                            "recordDate"
                          );
                          setFilteredData(sortedData);
                          setSorting(!sorting);
                        }}
                      >
                        Date <i class="fas fa-sort"></i>
                      </th>
                      <th
                        className={
                          "px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        }
                      >
                        Type
                      </th>
                    </tr>
                  </thead>
                </table>

                <AutoSizer>
                  {({ height, width }) => (
                    <Table
                      width={width}
                      height={height}
                      headerHeight={50}
                      rowHeight={60}
                      rowCount={filteredData.length}
                      rowGetter={({ index }) => filteredData[index]}
                      onScroll={({ clientHeight, scrollHeight, scrollTop }) => {
                        if (clientHeight + scrollTop >= scrollHeight) {
                          getSmsLog();
                        }
                      }}
                    >
                      <Column
                        width="100%"
                        dataKey="uid"
                        cellRenderer={renderRow}
                        flexGrow={1}
                      />
                    </Table>
                  )}
                </AutoSizer>

              </>
            )
          ) : (
            <div
              style={{
                width: "100%",
                height: "100px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <PuffLoader />
            </div>
          )}
        </div>
      )}
    </BottomScrollListener>
  );
};

export default SmsLogs;
