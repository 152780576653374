import React from "react";

import { PuffLoader } from "react-spinners";
import { listPermission } from "api/admin/requests";
import Toggler from "./Toggler";

const Permissions = () => {

    const [loader, setLoader] = React.useState(false);
    const [permissions, setPermissions] = React.useState([]);

    const fetchPermission = async () => {
        setLoader(true);
        const data = await listPermission()
        setLoader(false);
        setPermissions(data)
    }


    React.useEffect(() => {
        fetchPermission();
        return () => setPermissions([]);
    }, []);

    return (
        <div
            className={
                "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white"
            }
            style={{ height: 535, overflowY: "scroll" }}
        >
            <div className="rounded-t mb-0 px-4 py-3 border-0">
                <div className="flex flex-wrap items-center">
                    <div className="relative w-full px-4 max-w-full flex flex-grow flex-1 justify-between">
                        <h3 className={"font-semibold text-lg text-blueGray-700"}>
                            Permissions
                        </h3>
                    </div>
                </div>
            </div>
            <div className="block w-full overflow-x-auto">
                {loader ? (
                    <div
                        style={{
                            width: "100%",
                            height: "100px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <PuffLoader />
                    </div>
                ) : (
                    <table className="items-center w-full bg-transparent border-collapse">
                        <thead>
                            <tr>
                                <th
                                    className={
                                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left g-blueGray-50 text-blueGray-500 border-blueGray-100"
                                    }
                                    style={{ cursor: "pointer" }}
                                >
                                    permission name
                                </th>
                                <th
                                    className={
                                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left g-blueGray-50 text-blueGray-500 border-blueGray-100"
                                    }
                                    style={{ cursor: "pointer" }}
                                >
                                    isRequired
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {permissions.map(
                                (permission, key) =>
                                    <tr
                                        id={`deviceRow_${key}`}
                                        className="deviceRow"
                                        key={key}
                                        style={{ backgroundColor: "white" }}
                                    >

                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-lg whitespace-nowrap p-4 text-left text-blueGray-700">
                                            <div>
                                                {permission.name}
                                            </div>
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-lg whitespace-nowrap p-4 text-left text-blueGray-700">
                                            <div>
                                                <Toggler permission={permission} />
                                            </div>
                                        </td>
                                    </tr>
                            )}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
};

// const mapStateToProps = (state) => {
//   const permissions = state.deviceApi;
//   return {
//     permissions,
//   };
// };

export default Permissions;
