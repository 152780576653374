
import Switch from "react-switch";
import React from "react";
import { updatePermission } from "api/admin/requests";
import { PuffLoader } from "react-spinners";

const Toggler = ({ permission }) => {
    const [checked, setChecked] = React.useState(permission.isRequired ? true : false)
    const [loader, setLoader] = React.useState(false)

    function handleChange(checked) {
        setChecked(checked)
        setLoader(true)
        updatePermission({ name: permission.name, isRequired: checked }, (data) => {
            console.log("done")
            setLoader(false)
        });
    }

    return (
        <>
            {loader ? <div
                style={{
                    width: "100%",
                    height: "80px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <PuffLoader />
            </div> : <Switch onChange={handleChange} checked={checked} />}

        </>
    )
}

export default Toggler;
